exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-achievements-js": () => import("./../../../src/pages/achievements.js" /* webpackChunkName: "component---src-pages-achievements-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-carrier-js": () => import("./../../../src/pages/carrier.js" /* webpackChunkName: "component---src-pages-carrier-js" */),
  "component---src-pages-collaboration-js": () => import("./../../../src/pages/collaboration.js" /* webpackChunkName: "component---src-pages-collaboration-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contract-logistics-js": () => import("./../../../src/pages/contract-logistics.js" /* webpackChunkName: "component---src-pages-contract-logistics-js" */),
  "component---src-pages-csr-js": () => import("./../../../src/pages/csr.js" /* webpackChunkName: "component---src-pages-csr-js" */),
  "component---src-pages-ecommerce-js": () => import("./../../../src/pages/ecommerce.js" /* webpackChunkName: "component---src-pages-ecommerce-js" */),
  "component---src-pages-forwarding-js": () => import("./../../../src/pages/forwarding.js" /* webpackChunkName: "component---src-pages-forwarding-js" */),
  "component---src-pages-global-js": () => import("./../../../src/pages/global.js" /* webpackChunkName: "component---src-pages-global-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intermodal-js": () => import("./../../../src/pages/intermodal.js" /* webpackChunkName: "component---src-pages-intermodal-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-logistics-js": () => import("./../../../src/pages/logistics.js" /* webpackChunkName: "component---src-pages-logistics-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-paperliner-js": () => import("./../../../src/pages/paperliner.js" /* webpackChunkName: "component---src-pages-paperliner-js" */),
  "component---src-pages-payments-js": () => import("./../../../src/pages/payments.js" /* webpackChunkName: "component---src-pages-payments-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-transport-js": () => import("./../../../src/pages/transport.js" /* webpackChunkName: "component---src-pages-transport-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-document-js": () => import("./../../../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */)
}

